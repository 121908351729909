import React from 'react';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import i18n from '../../../i18n/i18n';
import { wentWrong } from "../../../utils/ToastConfig";
import axios from '../../../utils/AxiosWrapper';
import checkboxHOC from "react-table/lib/hoc/selectTable";
import { Delete } from '../../../utils/Delete';
import AddWorkerActivities from './AddWorkerActivities';
import css from './viewWorkerActivities.module.css';
import AddIcon from '@material-ui/icons/Add';
import SearchBar from 'material-ui-search-bar';
import { Button, Tooltip, IconButton, Menu } from '@material-ui/core';
import CalendarMonthIcon from '@material-ui/icons/DateRangeSharp';
import { DateRange } from 'react-date-range';
import * as Icons from '@material-ui/icons';
import PreviewWorkActivity from './PreviewWorkActivity.js';
import { Export } from '../../../utils/Export.js';
import moment from 'moment';
import { Tooltip as Tooltip2 } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { PulseLoader } from 'react-spinners';

var FileSaver = require('file-saver');
var XLSX = require('xlsx');

const styles = theme => ({ 
    primaryMain: { backgroundColor: theme.palette.primary.main },
    paginationButton: {
        borderRadius: '50%',
        width: '30px',
        height: '30px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#5EB3E4',
        color: 'white',
        border: 'none',
        cursor: 'pointer'
    },
    headerStyle: { textAlign: 'center',
        textTransform: 'uppercase',
        paddingTop: 10,
        paddingBottom: 10
        },
 });
 
class ViewWorkerActivities extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            workplaceType: [], fieldType: [], sectorType: [], workerUnitType: [], groupType: [], workingOperationType: [],
            showFilters: false, loading: false,
            page: 0, pageSize: 10, sorted: null, filtered: null,
            selection: [], selectAll: false, selectedRow: [],
            totalPages: 0, totalData: 0,
            add: false, edit: false, delete: false,
            data: [], valuta: '', valuta_code: '', mounting: true,

            allActivities: [],
            addNewActivity: false,
            previewWorkActivity: false,
            deleteActivity: false,
            editWorkerActivity: false,
            itemForEditing: null,
            itemForPreview: null,
            searchBarValue: "",
            anchorDatePicker: null,
            selectedDateRange: {
                endDate: null,
                selection: {
                    startDate: null,
                    endDate: null,
                    key: 'selection',
                },
                startDate: null,
            },
            selectedWorkingOperation: "",
            selectedField: "",
            selectedOperator: "",
            selectedSector: "",
            filteredActivities: [],


            workingOperations: [],
            fields: [],
            operators: [],
            sectors: [],

            numberOfAllWA: 0,
            numberOfFilteredWA: 0,
            totalCostAllWA: 0,
            totalCostFilteredWA: 0,

            currentPage: 0, // index based
            pageCount: 1,
            itemsPerPage: 6,
            exportWindow: false,
            docTitle: null,

            dataPlata: null,
            exportData: null,
            data1: [],
            load: true,
            startDate: "",
            endDate: ""
        };
    }

    componentDidMount() {
        this.getOperations();
        this.getFields();
        this.getSectors();
        this.getTotalSumAndCount();
        this.getOperators();
        this.getValuta();
    }

    getValuta() {
        axios.get(`api/valuta/get_valuta_id_naziv`)
            .then(res => {
                let data = res.data.data
                this.setState({ valuta_code: data.valuta_code })
                this.setState({ valuta: data.valuta })
            })
            .catch(error => { console.log('error', error); })
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.searchBarValue !== this.state.searchBarValue ||
            prevState.selectedWorkingOperation !== this.state.selectedWorkingOperation ||
            prevState.selectedDateRange !== this.state.selectedDateRange ||
            prevState.selectedField !== this.state.selectedField ||
            prevState.selectedSector !== this.state.selectedSector ||
            prevState.selectedOperator !== this.state.selectedOperator) {
                this.setState({
                    load: true
                })
                this.reactTable.fireFetchData();
        }
    }

    getData1(state) {
        this.setState({
            load: true
        })

        let filtered = [...state.filtered];
        let page = state.page;
        let formattedStartDate = '';
        let formattedEndDate= '';

        if (this.state.searchBarValue) {
            filtered.push({ id: 'radnik_ime_prezime', value: this.state.searchBarValue });
        }

        if (this.state.selectedWorkingOperation) {
            filtered.push({ id: 'radna_operacija_naziv', value: this.state.selectedWorkingOperation });
        }

        if (this.state.selectedField) {
            filtered.push({ id: 'id_tabla', value: this.state.selectedField });
        }

        if (this.state.selectedOperator) {
            filtered.push({ id: 'id_korisnik_kreirao', value: this.state.selectedOperator });
        }

        if (this.state.selectedSector) {
            filtered.push({ id: 'id_sektor_u_momentu_kreiranja', value: this.state.selectedSector });
        }

        if (this.state.selectedDateRange.startDate) {
            let startDateObject = new Date(this.state.selectedDateRange.startDate);
            formattedStartDate = moment(startDateObject).format("MMM DD YYYY HH:mm:ss");

            let endDateObject = new Date(this.state.selectedDateRange.endDate);
            endDateObject.setHours(23, 59, 59, 999);
            formattedEndDate = moment(endDateObject).format("MMM DD YYYY HH:mm:ss");
        }

        this.setState({ data1: [] });
        axios.get(`api/worker_activities/read`, {
            params: { page: page, pageSize: state.pageSize, sorted: state.sorted, filtered: filtered, startDate: formattedStartDate, endDate: formattedEndDate }
        })
            .then(res => {
                const formattedFilteredCount = parseFloat(res.data.filteredSum).toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
                this.setState({
                    exportData: res.data.exportData,
                    data1: res.data.data,
                    totalPages: res.data.total_pages,
                    numberOfFilteredWA: res.data.filteredCount,
                    totalCostFilteredWA: formattedFilteredCount,
                    load: false
                },
            );
            })
            .catch(error => {
                wentWrong('Something went wrong, please try again!');
                console.log('Error 1 (getData): ', error);
            })
    }

    getTotalSumAndCount() {
        axios.get(`api/worker_activities/get_total_count_and_sum`)
            .then(res => {
                let totalSum = res.data.data[0].totalSum;
                const formattedFilteredCount = parseFloat(totalSum).toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
                if(totalSum == 'NaN')
                    totalSum = "0.00";
                this.setState({
                    numberOfAllWA: res.data.data[0].totalCount,
                    totalCostAllWA: formattedFilteredCount
                },
            );
            })
            .catch(error => {
                wentWrong('Something went wrong, please try again!');
                console.log('Error 1 (getData): ', error);
            })
    }

    getOperations(edit) {
        axios.get(`api/working_operation/drop_down_operations_for_worker_activities`)
            .then(res => {
                const operations = res.data.data
                .map(item => ({ id: item.id, value: item.naziv }));
                if(edit){
                       let workingOperationsEdit= operations
                       let temp = [...this.state.workingOperations];
                       
                        for (let i = 0; i < workingOperationsEdit.length; i++) {
                            let element = workingOperationsEdit[i];
                        
                            if (!temp.some(item => item.id === element.id)) {
                                temp.push(element);
                            }
                        }
                        this.setState({
                            workingOperations: temp,
                        });
                }
                else{
                    this.setState({
                        workingOperations: operations
                    },);
                }
                
            })
            .catch(error => {
                wentWrong('Something went wrong, please try again!');
                console.log('Error 1 (getData): ', error);
            })
    }

    getFields(edit) {
        axios.get(`api/field/drop_down_fields_for_worker_activities`)
            .then(res => {
                const dataFields = res.data.data
                .map(item => ({ id: item.id, value: item.naziv }));
                if(edit){
                    let workingFieldsEdit= dataFields
                    let temp = [...this.state.fields];
                    
                     for (let i = 0; i < workingFieldsEdit.length; i++) {
                         let element = workingFieldsEdit[i];
                     
                         if (!temp.some(item => item.id === element.id)) {
                             temp.push(element);
                         }
                     }
                     this.setState({
                        fields: temp,
                     });
             }
             else{
                this.setState({
                    fields: dataFields
                    },
                );
            }
            })
            .catch(error => {
                wentWrong('Something went wrong, please try again!');
                console.log('Error 1 (getData): ', error);
            })
    }

    getOperators() {
        axios.get(`api/korisnik/drop_down_operaters_for_worker_activities`)
            .then(res => {
                const dataOperators = res.data.data
                .map(item => ({ id: item.id, value: item.rukovodilac_ime_prezime }));
                this.setState({
                    operators: dataOperators
                },
            );
            })
            .catch(error => {
                wentWrong('Something went wrong, please try again!');
                console.log('Error 1 (getData): ', error);
            })
    }

    getSectors(edit) {
        axios.get(`api/sektor/drop_down_sectors_for_worker_activities`)
            .then(res => {
                const dataSectors = res.data.data
                .map(item => ({ id: item.id, value: item.naziv }));
                if(edit){
                    let workingSectorsEdit= dataSectors
                    let temp = [...this.state.sectors];
                    
                     for (let i = 0; i < workingSectorsEdit.length; i++) {
                         let element = workingSectorsEdit[i];
                     
                         if (!temp.some(item => item.id === element.id)) {
                             temp.push(element);
                         }
                     }
                     this.setState({
                        sectors: temp,
                     });
             }
             else{
                this.setState({
                    sectors: dataSectors
                    },
                );
            }
            })
            .catch(error => {
                wentWrong('Something went wrong, please try again!');
                console.log('Error 1 (getData): ', error);
            })
    }

    handleAddNewActivity = () => {
        if (this.reactTable) {
            const { page, pageSize } = this.reactTable.state;
            if (page !== 0 || pageSize !== this.state.pageSize) {
                this.reactTable.state.page = 0;
            }
            this.resetMyFilters();
            this.getTotalSumAndCount();
            this.getOperations();
            this.getFields();
            this.getSectors();
            this.handleCloseAddNewActivity();
        }
    }

    handleCloseAddNewActivity= () =>{
        this.setState({
            addNewActivity: false
        })
    }

    handleOpenAddNewActivity= () =>{
        this.setState({
            addNewActivity: true
        })
    }

    openDeleteActivity = (id) => {
        this.setState({ deleteActivity: true, deleteItemId: id });
    }

    openEditActivity = (item) => {
        this.setState({ editWorkerActivity: true, itemForEditing: item })
    }

    handleCloseEditActivity = () => {
        this.setState({ editWorkerActivity: false }, () => {
            this.setState({
                load: true
            })
            this.reactTable.fireFetchData();
            this.getTotalSumAndCount();
            this.getOperations(true);
            this.getFields(true);
            this.getSectors(true);
        })
    }

    openPreviewActivity = (item) => {
        this.setState({ previewWorkActivity: true, itemForPreview: item });
    }

    handleClosePreviewActivity = () => {
        this.setState({ previewWorkActivity: false });
    }

    deleteWorkerActivity = () => {
        axios.post(`api/worker_activities/delete`, { selection: [this.state.deleteItemId] })
            .then(res => {
                this.setState({ deleteActivity: false }, () => {
                    // this.fetchAllWorkerActivities();
                    this.setState({
                        load: true
                    })
                    this.reactTable.fireFetchData();
                    this.getTotalSumAndCount();
                    
                });
            })
            .catch(err => {
                this.setState({ deleteActivity: false });
                console.log('Error: ', err);
            })
    }

    handleSearchBar = (searchVal) => {
        this.setState({
            searchBarValue: searchVal,
        });
    }

    handleDatePickerClick = (e) => {
        this.setState({ anchorDatePicker: e.currentTarget });
    };

    handleDatePickerClose = () => {
        this.setState({ anchorDatePicker: null });
    };
    handleChangeDate = (selectedDate) => {
        this.setState({
            selectedDateRange: selectedDate.range1,
        });
    }

    handleChangeWorkingOperation = (e) => {
        this.setState(
            {
                selectedWorkingOperation: e.currentTarget.value,
            });
    }

    handleChangeField = (e) => {
        this.setState(
            {
                selectedField: e.currentTarget.value,
            });
    }

    handleChangeOperator = (e) => {
        this.setState(
            {
                selectedOperator: e.currentTarget.value,
            });
    }

    handleChangeSector = (e) => {
        this.setState(
            {
                selectedSector: e.currentTarget.value,
            });
    }

    resetMyFilters = () => {
        this.setState({
            searchBarValue: "",
            selectedWorkingOperation: "",
            selectedDateRange: {
                startDate: null,
                endDate: null,
                selection: {
                    startDate: null,
                    endDate: null,
                    key: 'selection',
                }
            },
            selectedField: "",
            selectedOperator: "",
            selectedSector: "",
            currentPage: 0,
        })
    }

    handleCloseExportWindow = () => {
        this.setState({ exportWindow: false })
    }

    async exportDocument(title, data) {

        let user = this.getUser()
        
        let start, end;
        let odabranDatum;
        let wss;
        let newDataPlata;

            if(this.state.selectedDateRange.startDate && this.state.selectedDateRange.endDate){

                let startDateObject = new Date(this.state.selectedDateRange.startDate);
                start = moment(startDateObject).format("MMM DD YYYY HH:mm:ss");
    
                let endDateObject = new Date(this.state.selectedDateRange.endDate);
                endDateObject.setHours(23, 59, 59, 999);
                end = moment(endDateObject).format("MMM DD YYYY HH:mm:ss");

            odabranDatum = true

            await axios.post(`api/worker_activities/salary_calc`, { start: start, end: end })
            .then(res => {
                newDataPlata = res.data.data.rows.map((item, index) => {
                    let plata =  "Plata " +  '(' + user.valuta_naziv + ')'
                    return {
                        "Ime i prezime": item.radnik_ime_prezime,
                        "Broj aktivnosti": item.count,
                        [plata] : parseFloat(item.sum) ,
                    }
                })
            })
            .catch(err => {
                console.log('Error: ', err);
            })
            
            wss = XLSX.utils.json_to_sheet(newDataPlata);
            wss['!cols'] = [
                { wpx: 200 },
                { wpx: 100 },
                { wpx: 100 },
            ]
            wss['!rows'] = [{ hpx: 30 }];

            }
       

        let newData = this.state.exportData.map((item, index) => {
            let trosak =  "Troškovi " +  '(' + user.valuta_naziv + ')'
            return {
                "Ime i prezime": item.radnik_ime_prezime,
                "Radno mesto": item.radno_mesto_naziv,
                "Radna operacija": item.radna_operacija_naziv,
                "Proizvodne table": item.raspodela.map(tabla => `${tabla.tabla_naziv} ${(tabla.tabla_naziv && tabla.sorta_naziv) ? '-' : ''} ${tabla.sorta_naziv ? tabla.sorta_naziv : ''}`).join('\n'),
                Rukovodilac: item.rukovodilac_ime_prezime,
                Ogranak: item.sektor_naziv,
                [trosak] : parseFloat(item.ukupna_cena),
                "Ukupan trošak": index === 0 ? Number(this.state.totalCostFilteredWA).toFixed(2) + ` (${user.valuta_naziv})` : "",
            }
        });

        try {
            function s2ab(s) {
                const buf = new ArrayBuffer(s.length);
                const view = new Uint8Array(buf);
                for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xFF;
                return buf;
            }

            const ws = XLSX.utils.json_to_sheet(newData);

            ws['!cols'] = [
                { wpx: 200 },
                { wpx: 200 },
                { wpx: 120 },
                { wpx: 120 },
                { wpx: 100 },
                { wpx: 150 },
                { wpx: 150 },
                { wpx: 180 },
                { wpx: 120 },
                { wpx: 120 },
                { wpx: 120 },
                { wpx: 120 },
                { wpx: 120 }
            ]
            ws['!rows'] = [{ hpx: 30 }];

            const wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, "Aktivnosti radnika");
            if(odabranDatum) XLSX.utils.book_append_sheet(wb, wss , "Plata " + moment(this.state.selectedDateRange.startDate).format('DD.MM.YYYY') + "-" + moment(this.state.selectedDateRange.endDate).format('DD.MM.YYYY'));

            const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });
            const blob = new Blob([s2ab(wbout)], { type: 'application/octet-stream' });
            FileSaver.saveAs(blob, `${title || 'file'}.xlsx`);

        } catch (err) {
            wentWrong(i18n.t('Something went wrong'))
            console.log("BLOB ERROR : ", err)
        }

    };

    exportFunction = () => {
        this.exportDocument(this.state.docTitle, this.state.filteredActivities);
        this.setState({ exportWindow: false });
    }

    getUser() {
        let user = localStorage.getItem('user');
        let userObject = JSON.parse(user);
        !user && window.location.replace(window.location.origin);
        return userObject;
    }

    render() {
        let user = this.getUser();
        const { classes } = this.props;

        const columns = [
            { Header: i18n.t("Date"), accessor: 'datum', disableSortBy: true, headerStyle: { textAlign: 'center',
                textTransform: 'uppercase',
                paddingTop: 10,
                paddingBottom: 10,
                backgroundColor: "#04764e",
                color: '#FFFFFF'
                },
                Cell: ({ value }) => moment.utc(value).format('DD.MM.YYYY.')
            },
            { Header: i18n.t("Name and surname"), accessor: 'radnik_ime_prezime', headerStyle: { textAlign: 'center',
                textTransform: 'uppercase',
                paddingTop: 10,
                paddingBottom: 10,
                backgroundColor: "#04764e",
                color: '#FFFFFF'
                } },
            { Header: i18n.t("Workplace"), accessor: 'radno_mesto_naziv', headerStyle: { textAlign: 'center',
                textTransform: 'uppercase',
                paddingTop: 10,
                paddingBottom: 10,
                backgroundColor: "#04764e",
                color: '#FFFFFF'
                } },
            { Header: i18n.t("Working operation"), accessor: 'radna_operacija_naziv', headerStyle: { textAlign: 'center',
                textTransform: 'uppercase',
                paddingTop: 10,
                paddingBottom: 10,
                backgroundColor: "#04764e",
                color: '#FFFFFF'
                }},
            { Header: i18n.t("Field"), accessor: 'raspodela',
                getHeaderProps: () => ({
                    onClick: e => e.stopPropagation(),
                    style: {
                        cursor: 'default'
                    }
                }),
                headerStyle: { textAlign: 'center',
                textTransform: 'uppercase',
                paddingTop: 10,
                paddingBottom: 10,
                backgroundColor: "#04764e",
                color: '#FFFFFF'
                } , Cell: cell => (
                <div>
                    {cell.row.raspodela.slice(0, 1).map((item, index) => (
                        <div key={index}>
                        {item.tabla_naziv}
                        </div>
                    ))}
                    {cell.row.raspodela.length > 1 && (
                        <Tooltip
                        placement="right"
                        title={
                            <div style={{ fontSize: '14px', padding: '10px' }}>
                            <div style={{ borderBottom: '1px solid white', marginBottom: '10px', paddingBottom: '10px' }}>Sve proizvodne table:</div>
                            {cell.row.raspodela.map((item, index) => (
                                <div key={index}>
                                {item.tabla_naziv}
                                </div>
                            ))}
                            </div>
                        }
                        >
                        <div style={{ color: 'rgb(88, 181, 230)' }}>
                            ...prikaži više
                        </div>
                        </Tooltip>
                    )}
                </div>
              )},
            { Header: i18n.t("Manager"), accessor: 'rukovodilac_ime_prezime', headerStyle: { textAlign: 'center',
                textTransform: 'uppercase',
                paddingTop: 10,
                paddingBottom: 10,
                backgroundColor: "#04764e",
                color: '#FFFFFF'
                }},
            { Header: i18n.t('Sector'), accessor: 'sektor_naziv', headerStyle: { textAlign: 'center',
                textTransform: 'uppercase',
                paddingTop: 10,
                paddingBottom: 10,
                backgroundColor: "#04764e",
                color: '#FFFFFF'
                }},
            { Header: i18n.t('Total cost'),
                accessor: 'ukupna_cena', headerStyle: { textAlign: 'center',
                textTransform: 'uppercase',
                paddingTop: 10,
                paddingBottom: 10,
                backgroundColor: "#04764e",
                color: '#FFFFFF',
                }},
                
            {
                width: 80,
                Header: i18n.t('Details'),
                accessor: 'detalji',
                getHeaderProps: () => ({
                    onClick: e => e.stopPropagation(),
                    style: {
                        cursor: 'default'
                    }
                }),
                headerStyle: { textAlign: 'center',
                    textTransform: 'uppercase',
                    paddingTop: 10,
                    paddingBottom: 10,
                    backgroundColor: "#04764e",
                    color: '#FFFFFF'
                    },
                Cell: cell => (
                    <div>
                        <Tooltip2 title={i18n.t("Details")}>
                            <IconButton aria-label="details"
                                onClick={() => {
                                    this.openPreviewActivity(cell.row._original)
                                }}
                            >
                                <Icons.Visibility fontSize="default" />
                            </IconButton>
                        </Tooltip2>
                    </div>
                )
            },
            {
                width: 80,
                Header: i18n.t('Edit'),
                accessor: 'izmena',
                getHeaderProps: () => ({
                    onClick: e => e.stopPropagation(),
                    style: {
                        cursor: 'default'
                    }
                }),
                headerStyle: { textAlign: 'center',
                    textTransform: 'uppercase',
                    paddingTop: 10,
                    paddingBottom: 10,
                    backgroundColor: "#04764e",
                    color: '#FFFFFF'
                    },
                Cell: cell => (
                    <div>
                        {(cell.row._original.id_korisnik_kreirao === user.id || user.id_korisnik_grupa === 10)&&
                        (<Tooltip2 title={i18n.t("Edit")}>
                                    <IconButton aria-label="copy"
                                        onClick={() => {
                                            this.openEditActivity(cell.row._original);
                                        }}
                                    >
                                        <Icons.Edit fontSize="default" />
                                    </IconButton>
                                </Tooltip2>)}
                    </div>
                )
            },
            {
                width: 80,
                Header: i18n.t('Delete'),
                accessor: 'detalji',
                getHeaderProps: () => ({
                    onClick: e => e.stopPropagation(),
                    style: {
                        cursor: 'default'
                    }
                }),
                headerStyle: { textAlign: 'center',
                    textTransform: 'uppercase',
                    paddingTop: 10,
                    paddingBottom: 10,
                    backgroundColor: "#04764e",
                    color: '#FFFFFF'
                    },
                Cell: cell => (
                    <div>
                        {(cell.row._original.id_korisnik_kreirao === user.id || user.id_korisnik_grupa === 10) &&
                        (<Tooltip2 title={i18n.t("Delete")}>
                                    <IconButton aria-label="Delete"
                                        onClick={() => this.openDeleteActivity(cell.row._original.id)}
                                    >
                                        <Icons.DeleteOutline fontSize="default" />
                                    </IconButton>
                                </Tooltip2>)}
                    </div>
                )
            },
        ];

        return (
            <>
                <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>

                    <div className={css.searchWrap}>
                        <SearchBar
                            className={css.searchBar}
                            placeholder={"Pretraga po radniku"}
                            cancelOnEscape={true}
                            value={this.state.searchBarValue}
                            onChange={(searchValue) => this.handleSearchBar(searchValue)}
                            onCancelSearch={() => this.handleSearchBar('')}
                        />
                        <div style={{ display: 'flex' }}>
                            <div style={{ marginRight: '30px' }}>
                                <div style={{ backgroundColor: 'white', padding: '0 10px 0 10px', display: 'flex', minWidth: '300px', justifyContent: 'space-between', border: '1px solid #979797', boxShadow: '0px 1px 5px 0px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 3px 1px -2px rgba(0,0,0,0.12)' }}>
                                    <div
                                    >{i18n.t('Total number of worker activities')}:</div>
                                    <div>{this.state.numberOfAllWA}</div>
                                </div>
                                <div style={{ backgroundColor: 'white', padding: '0 10px 0 10px', display: 'flex', minWidth: '300px', justifyContent: 'space-between', border: '1px solid #979797', boxShadow: '0px 1px 5px 0px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 3px 1px -2px rgba(0,0,0,0.12)' }}>
                                    <div
                                    >{i18n.t('Total number of filtered worker activities')}:</div>
                                    <div>{this.state.numberOfFilteredWA}</div>
                                </div>
                            </div>

                            <div>
                                <div style={{ backgroundColor: 'white', padding: '0 10px 0 10px', display: 'flex', minWidth: '400px', justifyContent: 'space-between', border: '1px solid #979797', boxShadow: '0px 1px 5px 0px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 3px 1px -2px rgba(0,0,0,0.12)' }}>
                                    <div
                                    >{i18n.t('Total cost of worker activities')}:</div>
                                    <div>{this.state.totalCostAllWA} ({user.valuta_naziv})</div>
                                </div>
                                <div style={{ backgroundColor: 'white', padding: '0 10px 0 10px', display: 'flex', minWidth: '400px', justifyContent: 'space-between', border: '1px solid #979797', boxShadow: '0px 1px 5px 0px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 3px 1px -2px rgba(0,0,0,0.12)' }}>
                                    <div
                                    >{i18n.t('Total cost of filtered worker activities')}:</div>
                                    <div>{this.state.totalCostFilteredWA} ({user.valuta_naziv})</div>
                                </div>
                            </div>
                        </div>
                        <Button
                            variant="fab"
                            color="primary"
                            aria-label="Add"
                            className={css.addNewBtn}
                            onClick={() => { this.handleOpenAddNewActivity() }}
                        >
                            <AddIcon />
                            {i18n.t('Add Worker Activity')}
                        </Button>
                    </div>

                    <div className={css.filtersSection}>
                        <select className={css.dropDowns} value={this.state.selectedWorkingOperation} onChange={this.handleChangeWorkingOperation} >
                            <option value="">{i18n.t('Working operation')}</option>
                            {this.state.workingOperations && this.state.workingOperations.map((operation, i) => (
                                <option key={i} value={operation.value}>{operation.value}</option>
                            ))}
                        </select>

                        <Tooltip title={i18n.t('Date')}>
                            <IconButton aria-label="filter"
                                aria-owns={this.state.anchorDatePicker ? 'datePicker' : null}
                                aria-haspopup="true"
                                className={css.dateSelectBtn}
                                onClick={this.handleDatePickerClick}>
                                <CalendarMonthIcon />
                                <span className={css.dateSelectText}>{i18n.t('Date')}</span>
                            </IconButton>
                        </Tooltip>
                        <Menu
                            id="datePicker"
                            anchorEl={this.state.anchorDatePicker}
                            open={Boolean(this.state.anchorDatePicker)}
                            onClose={this.handleDatePickerClose}
                        >
                            <div className={css.calendarTitle}>{i18n.t('Date')}:</div>
                            <DateRange
                                ranges={[this.state.selectedDateRange]}
                                className={css.calendar}
                                onChange={(e) => {
                                    this.handleChangeDate(e)
                                }}
                                preview={{ color: '#58B4E5' }}
                            />
                        </Menu>

                        <select className={css.dropDowns} value={this.state.selectedField} onChange={this.handleChangeField} >
                            <option value="">{i18n.t('Field')}</option>
                            {this.state.fields && this.state.fields.map((field, i) => (
                                <option key={i} value={field.id}>{field.value}</option>
                            ))}
                        </select>

                        <select className={css.dropDowns} value={this.state.selectedOperator} onChange={this.handleChangeOperator} >
                            <option value="">{i18n.t('Manager')}</option>
                            {this.state.operators && this.state.operators.map((operator, i) => (
                                <option key={i} value={operator.id}>{operator.value}</option>
                            ))}
                        </select>

                        {user.id_korisnik_grupa === 10 && <select className={css.dropDowns} value={this.state.selectedSector} onChange={this.handleChangeSector} >
                            <option value="">{i18n.t('Sector')}</option>
                            {this.state.sectors && this.state.sectors.map((sector, i) => (
                                <option key={i} value={sector.id}>{sector.value}</option>
                            ))}
                        </select>}

                        <button className={css.resetButton} onClick={this.resetMyFilters}>{i18n.t('Reset filters')}</button>

                        <Button
                            onClick={() => this.setState({ exportWindow: !this.state.exportWindow })}
                            variant="fab"
                            color="secondary"
                            aria-label="add"
                            className={css.exportButton}
                        >
                            <span className="icon-download headerIcon" />
                        </Button>
                    </div>

                    <div style={{ border: '1px solid #cecece', backgroundColor: '#fff', margin: '0 30px 30px 30px', borderRadius: '3px', boxSizing: 'border-box', overflow: 'hidden', boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.5)', display: 'flex', flexDirection: 'column' }}>
                        
                    <ReactTable ref={r => (this.reactTable = r)}
                        manual
                        useSortBy
                        paging={true}
                        data={this.state.data1}
                        columns={columns}
                        keyField={'id'}
                        pageRangeDisplayed={false}
                        defaultPageSize={this.state.pageSize}
                        pages={this.state.totalPages}
                        showPaginationBottom={true}
                        onFetchData={(state) => this.getData1(state)}
                        firtelable={true}
                        previousText={'<'}
                        nextText={'>'}
                        pageText={''}
                        ofText={i18n.t('of')}
                        showPageSizeOptions={false}
                        showPagination={true}
                        rowsText={''}
                        PreviousComponent={(props) => (
                            <button {...props} 
                            className={
                                classes.paginationButton}
                            >{props.children}</button>
                        )}
                        NextComponent={(props) => (
                            <button {...props} 
                            className={
                                classes.paginationButton}
                            >{props.children}</button>
                        )}
                        getTrProps={() => {
                                return {
                                    style: {
                                        textAlign: 'center',
                                        paddingLeft: '10px',
                                        height: '50px',
                                        alignItems: 'center',
                                        overflowY: 'hidden',
                                        overflowX: 'hidden',
                                    }
                                };
                        }}

                        LoadingComponent={() => (
                            <div style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center'
                            }}>
                                <PulseLoader loading={this.state.load}/>
                            </div>                         
                        )}

                        onPageChange={() => {
                            this.state.load= true ;
                        }}

                        NoDataComponent={() => (
                            <div style={{ textAlign: 'center', padding: '20px', display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            marginTop: -300 }}>
                                {!this.state.load && <span>{i18n.t('No rows found')}</span>}
                            </div>
                        )}
                        />
                    </div>
                </div>

                {this.state.exportWindow && <Export
                    export={this.state.exportWindow}
                    onTextChange={({ title }) => { this.setState({ docTitle: title }); }}
                    handleClose={this.handleCloseExportWindow}
                    exportDoc={this.exportFunction} />}
                {this.state.previewWorkActivity && <PreviewWorkActivity add={true} edit={true} handleClose={this.handleClosePreviewActivity} data={this.state.itemForPreview} clearSelection={() => { }} />}
                {this.state.editWorkerActivity && <AddWorkerActivities add={true} edit={true} handleAdd={this.handleCloseEditActivity} handleClose={this.handleCloseEditActivity} data={this.state.itemForEditing} clearSelection={() => { }} />}
                {this.state.addNewActivity && <AddWorkerActivities add={true} edit={false} handleAdd={this.handleAddNewActivity} handleClose={this.handleCloseAddNewActivity} data={{}} clearSelection={() => { }} />}
                {this.state.deleteActivity && <Delete delete={true} handleClose={() => this.setState({ deleteActivity: false })} deleteItem={this.deleteWorkerActivity} />}
            </>
        );
    }
}

export default withStyles(styles)(ViewWorkerActivities);