import React from 'react';
import { connect } from 'react-redux';
import 'react-table/react-table.css';
import { withStyles } from '@material-ui/core/styles';
import { DialogTitle, IconButton, Table, TableBody, TableCell, TableHead, TableRow, } from '@material-ui/core';
import { getTransaction } from '../../actions/traffic/TrafficActions';
import i18n from '../../i18n/i18n';
const styles = theme => ({
});
const CustomTableCell = withStyles(theme => ({
    head: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.black,
        height: '45px',
        fontWeight: '400',
        fontSize: 17
    },
    body: {
        fontSize: 14,
    },
    root: {
        padding: '5px',
        textAlign: 'left',
        borderBottom: '1px solid rgba(224, 224, 224, 1)',
        verticalAlign: 'inherit'
    }
}))(TableCell);
export class ViewTransactions extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            transactions: []
        };
    }

    componentDidMount() {
        const { id_silos, getTransaction } = this.props;
        getTransaction(id_silos);
    }

    componentDidUpdate(prevProps) {
        const { transactions } = this.props;
        if (prevProps.transactions !== transactions) {
            this.setState({
                transactions
            })
        }
    }


    render() {
        const { transactionsFetching, handleClose, theme } = this.props;
        const { transactions } = this.state;
        if (transactionsFetching) {
            return <div style={{ display: 'flex', aligItems: 'center', justifyContent: 'center', textAlign: 'center', marginTop: '30%', fontSize: 20, fontWeight: 'bold' }}>{i18n.t('Loading transaction...')}</div>
        }
        const transactionsRender = Array.isArray(transactions) && transactions.length > 0 && transactions.map((item, index) => {
            return <TableRow key={index}>
                <CustomTableCell>{item.silos_izlaz}</CustomTableCell>
                <CustomTableCell>{item.silos_ulaz}</CustomTableCell>
                <CustomTableCell>{item.materijal_naziv}</CustomTableCell>
                <CustomTableCell>{item.iznos + ' T'}</CustomTableCell>
                <CustomTableCell>{item.datum}</CustomTableCell>
            </TableRow>
        })
        return (
            <div style={{ display: 'flex', flexDirection: 'column', minHeight: '400px' }}>
                <div>
                    <DialogTitle disableTypography={true} style={{ background: theme.palette.secondary.dark }} className="responsive-dialog-title">{i18n.t('Transactions')}
                        <IconButton onClick={handleClose}>
                            <span className="icon-cancel-circle closeIcon" />
                        </IconButton>
                    </DialogTitle>
                </div>
                <Table>
                    <TableHead>
                        <TableRow>
                            <CustomTableCell>{i18n.t('Destination')}</CustomTableCell>
                            <CustomTableCell>{i18n.t('Source')}</CustomTableCell>
                            <CustomTableCell>{i18n.t('Commodity')}</CustomTableCell>
                            <CustomTableCell>{i18n.t('Ammount')}</CustomTableCell>
                            <CustomTableCell>{i18n.t('Date')}</CustomTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {transactionsRender}
                    </TableBody>
                </Table>
            </div>
        )
    }


};

function mapStateToProps(state) {
    return {
        transactions: state.trafficReducer.transactions,
        transactionsFetching: state.trafficReducer.transactionsFetching,
        transactionsFetchingFailed: state.trafficReducer.transactionsFetchingFailed
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getTransaction: (id_silos) => dispatch(getTransaction(id_silos)),
    }
}

export default withStyles(styles, { withTheme: true })(connect(
    mapStateToProps,
    mapDispatchToProps
)(ViewTransactions))