import React from 'react';
import i18n from './../../../i18n/i18n';
import { Button, TextField, Grid, IconButton, Dialog, DialogContent, DialogTitle, Typography, Checkbox, DialogContentText } from '@material-ui/core';
import { wentRight, wentWrong } from "../../../utils/ToastConfig";
import axios from '../../../utils/AxiosWrapper';
import 'react-toastify/dist/ReactToastify.css';
import styles from './materials.module.css';
import ReactSelect from './../../../utils/ReactSelect';

export default class Materials extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            matList: [],
            materialGroupType: [],
            materialGroup: null,
            materialType: [],
            material: null,
            unitType: [],
            unit: null,
            amount: null,
            addDisabled: true,
            price: null,
            editMaterial: null
        };
    };

    componentDidMount() {
        axios.get('api/material/materijal_klijent')
            .then(res => {
                let data = res.data.data;
                let matList = [];

                let filtered = [];
                data.forEach(item => {
                    if (item.indikator_prinosa !== 1) {
                        filtered.push(item);
                    }
                })

                let materialType = filtered.map(item => {
                    let label = item.label;
                    let value = item.value;
                    let id_unit = item.id_unit;
                    let indikator_prinosa = item.indikator_prinosa;
                    let naziv = item.label;
                    let id_materijal = item.value;
                    let cena = item.cena;
                    // let jedinica_mere 
                    // let utroseno
                    // let prinos
                    matList.push({ label, value, naziv, indikator_prinosa, id_materijal, id_unit, cena });
                    return { label: item.label, value: item.value, id_unit, cena };
                });

                this.setState({ materialType, matList });
            })
            .catch(() => wentWrong(i18n.t('Something went wrong')))

        axios.get('api/unit/drop_down')
            .then(res => {
                let data = res.data.data;
                let unitType = data.map(item => { return { label: item.label, value: item.value } });
                this.setState({ unitType });
            })
            .catch(() => wentWrong(i18n.t('Something went wrong')))

        if (this.props.editMaterial !== null && this.props.activityObj) {

            // let mat = this.props.activityObj.materijali;
            // mat.filter(item => item.utroseno !== null);

            let temp = this.props.activityObj.materijali[this.props.editMaterial];

            axios.get('api/material/materijal_klijent_edit', { params: { id_materijal: temp.id_materijal } })
                .then(res => {
                    let data = res.data.data[0];
                    this.setState({
                        material: { cena: Number(data.cena), id_unit: data.id_unit, label: data.label, value: data.value },
                        unit: { label: data.unit_label, value: data.id_unit },
                        price: Number(temp.cena_po_jm),
                        amount: Number(temp.utroseno),
                        editMaterial: this.props.editMaterial,
                        addDisabled: false,
                    });
                })
                .catch(() => wentWrong(i18n.t('Something went wrong')))
        };
    };
    checkMaterialPrice(e) {
        if (e.value === this.state.material.id_unit) {
            this.setState({price: this.state.material.cena})
        }else{
            this.setState({price: ''})
        }
    }
    getByURL(url, obj) {
        axios.get(`api/${url}`)
            .then(res => this.setState({ [obj]: res.data.data }))
            .catch(error => {
                wentWrong(i18n.t('Something went wrong'))
                console.log('Error 2 (getByURL): ', error, ', URL: ', url);
            })
    };

    handleAdd = () => {
        if (this.state.material) {
            if (this.state.unit) {
                if (this.state.price) {
                    if (this.state.price > 0) {
                        if (this.state.amount) {
                            if (this.state.amount > 0) {
                                let matObj = {};

                                let data = this.state.matList.filter(item => item.value === this.state.material.value);
                                matObj = data[0];
                                matObj.jedinica_mere = this.state.unit.label;
                                matObj.id_unit = this.state.unit.value;
                                matObj.utroseno = Number(this.state.amount);
                                matObj.prinos = null;
                                matObj.cena_po_jm = Number(this.state.price);
                                matObj.id_materijal = this.state.material.value;
                                matObj.indikator_prinosa = null;
                                matObj.label = this.state.material.label;
                                matObj.naziv = this.state.material.label;
                                matObj.value = this.state.material.value;

                                if (this.props.editMaterial !== null && this.state.editMaterial !== null) {
                                    this.props.handleMaterialsObj(matObj, true, this.state.editMaterial)
                                }
                                else this.props.handleMaterialsObj(matObj, false, null);
                            }
                            else wentWrong(i18n.t('Amount must be more than 0'));
                        }
                        else wentWrong(i18n.t('You have to enter an amount'));
                    }
                    else wentWrong(i18n.t('Price must be more than 0'));
                }
                else wentWrong(i18n.t('You have to enter a price per unit!'));
            }
            else wentWrong(i18n.t('You have to choose a unit'));
        }
        else wentWrong(i18n.t('You have to choose a material'));
    };

    render() {
        console.log(this.state.material)
        return (
            <div style={{ padding: 20 }}>
                <Dialog open={this.props.open}
                    onClose={this.props.handleClose}
                    fullWidth={true}
                    disableBackdropClick
                    maxWidth="sm"
                    aria-labelledby="responsive-dialog-title">
                    <DialogTitle disableTypography={true} style={{ background: '#04764e' }} className="responsive-dialog-title">
                        {i18n.t('Add material')}
                        <IconButton onClick={this.props.handleClose}>
                            <span className="icon-cancel-circle closeIcon" />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent>
                        <div style={{ height: '10px' }} />
                        <div className={styles.content}>
                            <ReactSelect
                                closeMenuOnSelect={true}
                                isClearable={false}
                                isMulti={false}
                                value={this.state.material}
                                label={i18n.t('Material')}
                                maxMenuHeight={250}
                                onChange={(e) => this.setState({ material: e }, () => {
                                    let unit = this.state.unitType.filter(item => item.value === e.id_unit)
                                    this.setState({ unit: unit[0], price: e.cena });
                                })}
                                options={this.state.materialType}
                                className="multi-select-activity-form-new"
                                classNamePrefix="select"
                                placeholder={i18n.t('Select')}
                            />
                            <div style={{ height: '20px' }} />
                            {this.state.material &&
                                <ReactSelect
                                    closeMenuOnSelect={true}
                                    isClearable={false}
                                    isMulti={false}
                                    value={this.state.unit}
                                    label={i18n.t('Unit')}
                                    maxMenuHeight={200}
                                    onChange={(e) => [this.setState({ unit: e}), this.checkMaterialPrice(e)]}
                                    options={this.state.unitType}
                                    className="multi-select-activity-form-new2"
                                    classNamePrefix="select"
                                    placeholder={i18n.t('Select')}
                                />
                            }
                            <div style={{ height: '20px' }} />
                            {this.state.material &&
                                <TextField
                                    label={i18n.t('Price per unit')}
                                    value={this.state.price}
                                    type="number"
                                    style={{ width: '510px', marginLeft: '20px' }}
                                    InputLabelProps={{ shrink: this.state.price ? true : false }}
                                    onChange={(e) => {
                                        this.setState({ price: e.target.value }, () => {
                                            // this.setState({ addDisabled: false });
                                        })
                                    }} >
                                </TextField>
                            }
                            <div style={{ height: '20px' }} />
                            {this.state.unit &&
                                <TextField
                                    label={i18n.t('Amount spent')}
                                    value={this.state.amount}
                                    type="number"
                                    style={{ width: '510px', marginLeft: '20px' }}
                                    onChange={(e) => {
                                        this.setState({ amount: e.target.value }, () => {
                                            this.setState({ addDisabled: false });
                                        })
                                    }} >
                                </TextField>
                            }
                        </div>
                        <div className={styles.center}>
                            {/* <Button onClick={() => { this.setState({ fields: this.state.fieldType }) }} variant='contained' color='primary' style={{ margin: '0px 20px 10px 0px' }}>{i18n.t('Select all')}</Button> */}
                            <Button onClick={() => this.handleAdd()} disabled={this.state.addDisabled} variant='contained' color='primary' style={{ margin: '0px 20px 10px 0px' }}>{this.props.editMaterial ? i18n.t('Edit') : i18n.t('Add')}</Button>
                        </div>
                    </DialogContent>
                </Dialog>
            </div >
        );
    };
};