import React from "react"
import i18n from '../../../i18n/i18n';
import './styles.css'
import { TextField } from "@material-ui/core";

export default class ArrangeSeedlings extends React.Component {
    constructor(props) {
        super(props)

        this.contentRef = React.createRef()

        const editRows = this.props.raspored_redova.map(row => ({
            ...row,
            broj_sadnica: row.id_kultura_sorta === null ? 0 : row.broj_sadnica
        }));
        this.state = {
            editRows,
            rowChunks1: []
        }
    }

    updateNumberOfSeedlings = (row_no, newValue) => {
        const updateIndex = this.state.editRows.findIndex(row => row.broj_reda === row_no)
        let updateRow = { ...this.state.editRows[updateIndex] }
        updateRow.broj_sadnica = newValue

        const newRows = [
            ...this.state.editRows.slice(0, updateIndex),
            updateRow,
            ...this.state.editRows.slice(updateIndex + 1)
        ]

        this.setState({
            editRows: newRows,       
        },)
    };

    updateSort = (row_no, newValue) => {
        const updateIndex = this.state.editRows.findIndex(row => row.broj_reda === row_no)
        let updateRow = { ...this.state.editRows[updateIndex] }

        updateRow.id_kultura_sorta = parseInt(newValue)

        if( newValue === undefined || newValue === '' || newValue === null) {
            updateRow.broj_sadnica = 0
        }

        const newRows = [
            ...this.state.editRows.slice(0, updateIndex),
            updateRow,
            ...this.state.editRows.slice(updateIndex + 1)
        ]
        this.setState({
            editRows: newRows
        },)
    }

    makeRowTableChunks() {
        let rowChunks = []
        let chunkLength = Math.floor(this.props.broj_redova / 2) + 1
        let i = 0;

        if (this.props.sorte.length === 1 && this.props.id_table === undefined) {
            const id_kultura_sorta = this.props.sorte[0].id_kultura_sorta;
            this.state.editRows = this.state.editRows.map(row => ({
                ...row,
                id_kultura_sorta: id_kultura_sorta
            }));
        }

        while (i < this.props.broj_redova) {
            rowChunks.push(this.state.editRows.slice(i, i + chunkLength))
            i += chunkLength
        }
        return rowChunks
    }

    saveSeedlingsArrangement() {
        this.props.saveSeedlingsArrangement(this.state.editRows)
    }

    hasKulturaSorta (id_kultura_sorta) {
        return id_kultura_sorta !== undefined && id_kultura_sorta !== null && id_kultura_sorta !== '' && !Number.isNaN(id_kultura_sorta)
    }

    render() {
        return (
            <div className="arrange_seedlings_wrapper" style={{ margin: '20px', minHeight: '500px' }} ref={this.contentRef}>
                <div id="content">
                    <div className="rows_seedlings_tables">
                        {
                            this.makeRowTableChunks().map(rowsChunk => (
                                <table style={{marginRight: '32px'}}>
                                    <tr style={{ borderBottom: '2px solid rgb(118, 118, 118)' }}>
                                        <th>{i18n.t('Row number label')}</th>
                                        <th>{i18n.t('Sort/Hybrid')}</th>
                                        <th style={{ textAlign: 'left', paddingLeft: '16px' }}>{i18n.t('Number of seedlings label')}</th>
                                    </tr>
                                    {
                                        rowsChunk.map(row => {
                                            return (
                                                <tr>
                                                    <td style={{ textAlign: 'center', fontSize: '16px', fontWeight: '550' }}>{row.broj_reda}</td>
                                                    <td>
                                                        <TextField
                                                            disabled={this.props.editDisabled}
                                                            select
                                                            value={row.id_kultura_sorta}
                                                            SelectProps={{ native: true, }}
                                                            style={{ width: '200px', margin: '10px' }}
                                                            onChange={(e) => {
                                                                this.updateSort(row.broj_reda, e.target.value)
                                                            }} >
                                                            <option value='' />
                                                            {this.props.sorte.map((ks) => <option key={ks.id_kultura_sorta} value={ks.id_kultura_sorta}>{ks.naziv}</option>)}
                                                        </TextField>
                                                    </td>
                                                    <td style={{ paddingLeft: '15px', paddingRight: '15px' }}>
                                                        <TextField
                                                            disabled={this.props.editDisabled || !this.hasKulturaSorta(row.id_kultura_sorta)}
                                                            key={row.broj_reda}
                                                            placeholder={''}
                                                            type="number"
                                                            pattern="[0-9]*"
                                                            value={row.broj_sadnica}
                                                            onChange={(e) => {
                                                                const inputValue = e.target.value;
                                                                const parsedValue = inputValue === "" ? null : parseFloat(parseFloat(inputValue).toFixed(4));
                                                                this.updateNumberOfSeedlings(row.broj_reda, (parsedValue < 0 ? 0 : parsedValue));
                                                            }} />
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                </table>
                            ))
                        }
                    </div>
                </div>
            </div>
        )
    }

}